<template>
  <div class="serach_video_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <Post class="post" :postInfo="postInfo" v-for="postInfo in list" :key="postInfo.id" :page="params.pageNumber" :keyword="keyword" />
    </PullRefresh>
  </div>
</template>
<script>
import Post from '@/components/Post';
import { search } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';

export default {
  name: 'searchVideo',
  props: {
    keyword: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    Post,
    PullRefresh,
  },
  data() {
    return {
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        keyword: this.keyword,
        videoType: 'SP',
        pageNumber: this.params.pageNumber,
        pageSize: this.params.pageSize,
      };
      try {
        let res = await this.$Api(search, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }

      this.getList();
    },
    onRefresh() {
      this.params.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.serach_video_warp {
  .post {
    padding: 12px 16px 14px 16px;
    margin-bottom: 10px;
  }
  .post:first-child {
    margin-top: 10px;
  }
}
</style>
